export default function headerNavigation() {
  let navigationContainer;
  let navListYet;
  let navSublistYet;
  let headerNavList;

  if (document.querySelector('.header__bottom')) {
    navigationContainer = document.querySelector('.header__bottom .container');
    navListYet = document.querySelector('.navigation-list-yet');
    navSublistYet = document.querySelector('.navigation-sublist-yet');
    headerNavList = document.querySelector('#navigation-secondary-list');
  }

  function hideNavigationElements() {
    const navListYetStyles = window.getComputedStyle(navListYet);

    if (document.querySelector('.header__bottom')) {
      if ((headerNavList.offsetWidth +
        navListYet.offsetWidth +
        parseInt(navListYetStyles.marginLeft, 0) +
        parseInt(navListYetStyles.marginRight, 0) +
        50) > navigationContainer.offsetWidth) {
        navListYet.classList.remove('hide');
        navListYet.classList.add('show');

        while (((headerNavList.offsetWidth +
          navListYet.offsetWidth +
          parseInt(navListYetStyles.marginLeft, 0) +
          parseInt(navListYetStyles.marginRight, 0) +
          50) > navigationContainer.offsetWidth)) {
          navSublistYet.prepend(headerNavList.lastElementChild);
        }
      }
    }
  }

  if (window.innerWidth > 1024) {
    hideNavigationElements();
  }

  (function () {
    var throttle = function (type, name, obj) {
      obj = obj || window;
      var running = false;
      var func = function () {
        if (running) { return; }
        running = true;
        requestAnimationFrame(function () {
          obj.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      obj.addEventListener(type, func);
    };

    throttle('resize', 'optimizedResize');
  })();

  window.addEventListener('optimizedResize', () => {
    if (window.innerWidth > 1024) {
      hideNavigationElements();
    }
  });
}
