import UIkit, { modal } from 'uikit';
import deviceType from './modules/deviceType';
import dynamicAdapt from './modules/dynamic-adapt';
import headerMenu from './modules/headerMenu';
import headerNavigation from './modules/headerNavigation';

window.addEventListener('DOMContentLoaded', () => {
  deviceType();
  dynamicAdapt();
  headerMenu();
  headerNavigation();

  const header = document.querySelector('.header');
  const headerHeight = header.offsetTop;

  function changeHeaderColor() {
    if (window.pageYOffset > headerHeight) {
      header.classList.add('header_white');
    } else {
      header.classList.remove('header_white');
    }
  }

  window.addEventListener('scroll', () => {
    changeHeaderColor();
  });

  if (document.querySelector('.header').classList.contains('hide-at-scroll')) {
    (function () {

      var doc = document.documentElement;
      var w = window;

      var prevScroll = w.scrollY || doc.scrollTop;
      var curScroll;
      var direction = 0;
      var prevDirection = 0;

      var header = document.querySelector('.hide-at-scroll');

      var checkScroll = function () {

        /*
        ** Find the direction of scroll
        ** 0 - initial, 1 - up, 2 - down
        */

        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
          //scrolled up
          direction = 2;
        }
        else if (curScroll < prevScroll) {
          //scrolled down
          direction = 1;
        }

        if (direction !== prevDirection) {
          toggleHeader(direction, curScroll);
        }

        prevScroll = curScroll;
      };

      var toggleHeader = function (direction, curScroll) {
        if (direction === 2 && curScroll > 200) {

          //replace 52 with the height of your header in px

          header.classList.add('js-hide');
          prevDirection = direction;
        }
        else if (direction === 1) {
          header.classList.remove('js-hide');
          prevDirection = direction;
        }
      };

      window.addEventListener('scroll', checkScroll);

    })();
  }
});
