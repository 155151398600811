export default function headerMenu() {
  const iconMenu = document.querySelector('.icon-menu');
  const menu = document.querySelector('.menu');
  let unlock = true;

  iconMenu.addEventListener('click', () => {
    if (unlock) {
      iconMenu.classList.toggle('js-active');
      menu.classList.toggle('js-active');
      document.body.classList.toggle('js-lock');
      unlock = false;
    } else {
      menuClose();
    }
  });

  function menuClose() {
    iconMenu.classList.remove('js-active');
    menu.classList.remove('js-active');
    document.body.classList.toggle('js-lock');
    unlock = true;
  }
}
